<template>
  <img src="/img/line-logo.jpg" width="100" />
</template>
<script lang="ts">
import { Vue, Options } from 'vue-class-component'

@Options({
  components: {},
})
export default class AppLogoOnly extends Vue {}
</script>
