
import { Options, mixins } from 'vue-class-component'
import { maska } from 'maska'
import { ACTION_PROFILE } from '@/store/actions'
import AppLogoOnly from '@/components/common/AppLogoOnly.vue'
import LoginRegisterMixin from '@/components/account/mixins/LoginRegisterMixin.vue'
import errorHandler from '@/utils/errorHandler'

@Options({
  components: {
    AppLogoOnly,
  },
  directives: { maska },
})
export default class RegisterForm extends mixins(LoginRegisterMixin) {
  form = {
    display_name: '',
    email: '',
    password: '',
  }

  get joiningToken() {
    return this.$store.state.profile.joining_token
  }

  get requiredRule() {
    const requiredRule = {
      input_your_name: this.$t('validate.please_input_your_name'),
      email: this.$t('validate.please_input_your_valid_email'),
      password: this.$t('validate.please_input_your_valid_password'),
      password_again: this.$t('validate.please_input_your_valid_password_again'),
    }
    return requiredRule
  }

  async onSubmit() {
    this.$refs.formRef
      .validate()
      .then((success: boolean) => {
        if (!success) {
          return
        }
        this.doRegister()
      })
      .catch((error: unknown) => {
        console.log('error', error)
      })
  }

  async doRegister() {
    const { email, display_name, password } = this.form
    const { dispatch } = this.$store
    if (email && password) {
      await dispatch(ACTION_PROFILE.REGISTER, {
        email,
        password,
        display_name,
      }).catch((error: Error) => {
        errorHandler(error)
      })
    }
  }

  gotoHome() {
    this.$router.push({ name: 'home' })
  }

  onGotoLogin() {
    this.$router.push({ name: 'login' })
  }
}
