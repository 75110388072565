
import { Vue, Options } from 'vue-class-component'
import { maska } from 'maska'
import { Watch } from 'vue-property-decorator'

@Options({
  components: {},
  directives: { maska },
})
export default class LoginRegisterMixin extends Vue {
  get isAuth() {
    return this.$store.getters.isAuth
  }

  @Watch('isAuth')
  onAuthChange() {
    if (this.isAuth) {
      this.$router.push({ name: 'home' })
    }
  }
}
